<template>
    <Modal :mask-closable="false" :closable="false" v-model="isShow" :title="title" width="800" :loading="ajaxLoading">
        <div class="table">
            <Table :columns="columns" :data="dataList" @on-selection-change="onSelectionChange">
                <template slot-scope="{ row }" slot="goodMessage">
                    <div class="break_all">{{ row.name }}</div>
                    <div class="break_all">{{ row.data }}</div>
                </template>
                <template slot-scope="{ row }" slot="fahuoStatus">
                    <span :class="'now_state_' + row.fahuoStatus">{{ row.fahuoStatus == 1 ? '已发货' : '未发货' }}</span>
                </template>
            </Table>
        </div>
        <div class="form_item">
            <div class="form_label must_input">物流公司</div>
            <Select :transfer="true" class="width_300" v-model="formData.sfcMod">
                <Option v-for="info in sfcList" :key="info.key" :value="info.key">{{ info.name }}</Option>
            </Select>
        </div>
        <div class="form_item">
            <div class="form_label must_input">运单号</div>
            <Input class="width_300" v-model.trim="formData.sfcOrder" />
        </div>
        <!-- <div class="form_item">
            <div class="form_label align_self_start">备注</div>
            <Input type="textarea" :rows="4" class="width_300" v-model.trim="formData.note" />
        </div> -->
        <div v-if="!!isEdit" class="remark">仅可修改1次物流，请谨慎修改</div>
        <div slot="footer" class="relative width_100_percent flex align_center justify_center">
            <Button type="primary" ghost @click="onCancel">取消</Button>
            <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirm">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { validateForm } from '@/lib/util';
import { getSfcModList, getSfcStatus } from '@/lib/order';
import { reqOrderSfc } from '@/lib/request/auth2';
export default {
    name: 'sendOut',
    props: ['value', 'data'],
    data() {
        return {
            title: null,
            isShow: false,
            isEdit: false,
            detail: {},
            sfcList: [],
            formData: {
                sfcMod: null,
                sfcOrder: null,
                note: null,
            },
            columns: [
                // { type: 'selection', width: 60 },
                { slot: 'goodMessage', title: '商品信息', minWidth: 150 },
                { key: 'num', title: '数量' },
                { key: 'sfc_state', title: '状态' },
                { key: 'sfc_mod_str', title: '物流公司' },
                { key: 'sfc_order', title: '运单号' },
            ],
            dataList: [],
            checkedGood: [],
        };
    },
    watch: {
        value(newValue) {
            this.isShow = newValue;
        },
        data(newValue) {
            let detail = newValue.detail || {};
            this.detail = detail;
            this.formData.sfcMod = detail.sfc_mod;
            this.formData.sfcOrder = detail.sfc_order;

            let dataList = detail.goods || [];
            dataList.forEach(info => {
                info.sfc_state = getSfcStatus(detail.sfc_state);
                info.sfc_mod_str = detail.sfc_mod_str || '-';
                info.sfc_order = detail.sfc_order || '-';
            });
            this.dataList = dataList || [];
            this.title = `订单发货(${detail.order_no})——【共${dataList.length}件商品】`;

            let isEdit = !!newValue.isEdit;
            this.isEdit = isEdit;
            // this.title = `订单发货——【共${dataList.length}件商品，已发货${dataList.filter(info => info.fahuoStatus == 1).length}件商品】`;
            // if (isEdit) {
            //     this.checkedGood = (dataList.filter(info => info.fahuoStatus == 1) || []).map(info => info.id);
            // }
        },
    },
    mounted() {
        getSfcModList(list => {
            this.sfcList = list;
        });
    },
    methods: {
        onCancel() {
            this.isShow = false;
            this.$emit('input', false);
            window.setTimeout(() => {
                this.formData.sfcMod = '1';
                this.formData.sfcOrder = null;
                this.formData.note = null;

                this.hideAjaxLoading();
            }, 500);
        },
        onConfirm() {
            let { sfcMod, sfcOrder, note } = this.formData || {};
            let vs = [
                { value: sfcMod, tip: '请选择物流公司' },
                { value: sfcOrder, tip: '请输入运单号' },
            ];
            validateForm(vs)
                .then(() => {
                    // console.log(JSON.stringify(this.formData, null, '\t'));
                    this.showAjaxLoading();
                    reqOrderSfc({
                        orderNo: this.detail.order_no,
                        sfcMod,
                        sfcOrder,
                    })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.onCancel();
                            this.$emit('sendsuccess');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.fadeWarning(msg);
                });
        },
        onSelectionChange(evt) {
            this.checkedGood = (evt || []).map(info => info.id);
            console.log('onSelectionChange checkedGood=', this.checkedGood);
        },
    },
};
</script>
<style lang="less" scoped>
.form_label {
    width: 120px;
}
.table {
    & /deep/ span,
    & /deep/ div {
        font-size: 14px;
    }
}
.remark {
    font-size: 14px;
    color: red;
    text-align: center;
}
</style>
