<template>
    <Modal :mask-closable="false" :closable="false" v-model="isShow" title="退款" width="500" :loading="ajaxLoading">
        <div class="form_item">
            <div class="form_label">退款类型</div>
            仅退款
        </div>
        <div class="form_item">
            <div class="form_label must_input">退货原因</div>
            <Select v-model="formData.cause">
                <Option value="1">缺货</Option>
                <Option value="2">用户不想要了</Option>
                <Option value="3">其它原因</Option>
            </Select>
        </div>
        <div class="form_item">
            <div class="form_label must_input">退货金额</div>
            <Input class="width_100" v-model.trim="formData.money" />
            <div class="tip_txt">最多可退{{ maxMoney }}元</div>
        </div>
        <div class="form_item">
            <div class="form_label align_self_start">退款备注</div>
            <Input type="textarea" :rows="4" class="width_300" v-model.trim="formData.note" />
        </div>
        <div slot="footer" class="relative width_100_percent flex align_center justify_center">
            <Button type="primary" ghost @click="onCancel">取消</Button>
            <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirm">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { isNotEmptyString, isNumberGt0 } from '@/lib/util';
export default {
    name: 'refund',
    props: ['value', 'refundId'],
    data() {
        return {
            isShow: false,
            maxMoney: 200,
            formData: {
                id: null,
                cause: '1',
                money: null,
                note: null,
            },
        };
    },
    watch: {
        value(newValue) {
            this.isShow = newValue;
        },
        refundId(newValue) {
            this.formData.id = newValue;
        },
    },
    methods: {
        onCancel() {
            this.isShow = false;
            this.$emit('input', false);
            window.setTimeout(() => {
                this.formData.id = null;
                this.formData.cause = '1';
                this.formData.money = null;
                this.formData.note = null;

                this.hideAjaxLoading();
            }, 500);
        },
        onConfirm() {
            let { id, cause, money, note } = this.formData || {};
            if (!isNotEmptyString(money)) {
                this.fadeWarning('请输入退货金额');
                return;
            }
            if (!isNumberGt0(money)) {
                this.fadeWarning('退货金额输入不规范');
                return;
            }
            let maxMoney = this.maxMoney;
            if (money > maxMoney) {
                this.fadeWarning(`退货金额不能超过${maxMoney}元`);
                return;
            }

            this.fadeAlert('操作成功');
            this.onCancel();
        },
    },
};
</script>
<style lang="less" scoped>
.form_label {
    width: 120px;
}
</style>
