<template>
    <Modal :mask-closable="false" :closable="false" v-model="isShow" title="发送模版" width="500" :loading="ajaxLoading">
        <div class="form_item">
            <div class="form_label must_input">选择模版</div>
            <Select v-model="formData.templateId">
                <Option v-for="info in templateList" :key="info.id" value="info.id">{{ info.title }}</Option>
            </Select>
        </div>
        <div slot="footer" class="relative width_100_percent flex align_center justify_center">
            <Button type="primary" ghost @click="onCancel">取消</Button>
            <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirm">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { createId, isNotEmptyString } from '@/lib/util';
export default {
    name: 'sendTemplate',
    props: ['value', 'id'],
    data() {
        return {
            isShow: false,
            formData: {
                id: null,
                templateId: null,
            },
            templateList: [],
        };
    },
    watch: {
        value(newValue) {
            this.isShow = newValue;
        },
        id(newValue) {
            this.formData.id = newValue;
        },
    },
    mounted() {
        this.getTemplateList();
    },
    methods: {
        getTemplateList() {
            this.templateList = Array.from({ length: 5 }, (v, k) => ({
                id: createId(),
                title: '模版模版模版' + k,
            }));
        },
        onCancel() {
            this.isShow = false;
            this.$emit('input', false);
            window.setTimeout(() => {
                this.formData.id = null;
                this.formData.templateId = null;

                this.hideAjaxLoading();
            }, 500);
        },
        onConfirm() {
            let { id, templateId } = this.formData || {};
            if (!isNotEmptyString(templateId)) {
                this.fadeWarning('请选择模版');
                return;
            }

            this.fadeAlert('操作成功');
            this.onCancel();
        },
    },
};
</script>
<style lang="less" scoped>
.form_label {
    width: 120px;
}
</style>
