<template>
    <div class="good_order_view">
        <Card class="card_item" dis-hover>
            <div>
                <h3>{{ title }}</h3>
            </div>
            <div>{{ subTitle }}</div>
            <div class="mt_10 steps_container">
                <Steps v-if="detail.status == orderStatusDef.afterSale" :current="stepCurrent">
                    <Step title="提交订单" :icon="stepCurrent == 0 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                    <Step title="买家付款" :icon="stepCurrent == 1 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                    <Step title="退款售后" :icon="stepCurrent == 2 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                </Steps>
                <Steps v-else-if="detail.status != orderStatusDef.orderClose" :current="stepCurrent">
                    <Step title="提交订单" :icon="stepCurrent == 0 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                    <Step title="买家付款" :icon="stepCurrent == 1 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                    <Step title="商家发货" :icon="stepCurrent == 2 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                    <Step title="买家确认收货" :icon="stepCurrent == 3 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                </Steps>
                <Steps v-else :current="stepCurrent">
                    <Step title="提交订单" :icon="stepCurrent == 0 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                    <Step title="关闭订单" :icon="stepCurrent == 1 ? 'md-checkmark-circle' : 'md-radio-button-on'"></Step>
                </Steps>
            </div>
            <div class="mt_10 flex align_center">
                <div class="align_self_start width_100 flex_shrink_0">买家备注：</div>
                <div>{{ detail.userNote || '无' }}</div>
            </div>
            <!-- <div class="mt_10 flex align_center">
                <div>卖家备注</div>
                <Button class="ml_10" type="primary" size="small">添加备注</Button>
            </div> -->

            <mySpin fixed :loading="ajaxLoading"></mySpin>
        </Card>

        <Card class="card_item" title="订单信息" dis-hover>
            <div class="relative flex flex_wrap align_start">
                <div class="form_item">
                    <div class="form_label">收货人</div>
                    <div class="form_value">{{ detail.userName }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">联系电话</div>
                    <div class="form_value">{{ detail.userTel }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">收货地址</div>
                    <div class="form_value">{{ detail.userAddress }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">配送方式</div>
                    <div class="form_value">快递配送</div>
                </div>
                <div class="form_item">
                    <div class="form_label">订单编号</div>
                    <div class="form_value">{{ detail.order_no }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">微信交易单号</div>
                    <div class="form_value">{{ detail.wx_order_no }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">下单时间</div>
                    <div class="form_value">{{ detail.ctime }}</div>
                </div>
                <template v-if="[orderStatusDef.afterSale, orderStatusDef.sendIng, orderStatusDef.sendEd, orderStatusDef.orderEd].includes(detail.status)">
                    <div class="form_item">
                        <div class="form_label">付款时间</div>
                        <div class="form_value">{{ detail.payAt }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_label">付款金额</div>
                        <div class="form_value">{{ detail.money | cny }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_label">支付方式</div>
                        <div class="form_value">微信支付</div>
                    </div>
                </template>
                <div class="form_item">
                    <div class="form_label">订单状态</div>
                    <div class="form_value">{{ getOrderStatus(detail.status) }}</div>
                </div>
                <div v-if="detail.status == orderStatusDef.afterSale && detail.applyState == applyStateDef.ed && detail.applyRefundState == applyRefundStateDef.agree" class="form_item">
                    <div class="form_label">退款金额</div>
                    <div class="form_value">{{ detail.refund_price | cny }}</div>
                </div>
            </div>
        </Card>

        <Card class="card_item" title="商品信息" dis-hover>
            <Table size="small" stripe :columns="goodColumn" :data="detail.goods">
                <template slot-scope="{ row }" slot="goodMessage">
                    <div class="flex align_center justify_center item">
                        <div>
                            <previewImage :thumWidth="60" :thumHeight="60" :src="row.icon"></previewImage>
                        </div>
                        <div class="flex column align_start justify_center ml_10 good_message">
                            <div class="break_all">{{ row.name }}</div>
                            <div class="break_all">{{ row.data }}</div>
                        </div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="price">
                    {{ row.money | cny }}
                </template>
                <template slot-scope="{ row }" slot="realPay">
                    {{ row.money | cny }}
                </template>
                <template slot="afterSale">
                    {{ detail.status == orderStatusDef.afterSale ? (detail.applyState == applyStateDef.ing ? '买家发起售后申请' : detail.applyState == applyStateDef.ed ? '售后完成' : '-') : '-' }}
                </template>
            </Table>
        </Card>

        <Card v-if="detail.status == orderStatusDef.sendEd || detail.status == orderStatusDef.orderEd || detail.status == orderStatusDef.afterSale" class="card_item" title="物流信息" dis-hover>
            <Table size="small" stripe :columns="expressColumn" :data="expressList">
                <template slot-scope="{ row }" slot="manager">
                    <div class="flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onShowExpressMessage()">查看物流</Button>
                        <Button v-if="detail.orderStatus == orderStatusDef.sendEd" class="ml_5" type="success" size="small" ghost @click="onShowSendTemplate(row)">发送模版</Button>
                    </div>
                </template>
            </Table>
        </Card>

        <div class="relative flex align_center justify_center mt_20 btns">
            <Button v-if="detail.status == orderStatusDef.sendIng" type="primary" @click="onSendOut(0)">发货</Button>
            <Button v-if="detail.status == orderStatusDef.sendEd" type="primary" @click="onSendOut(1)">修改物流</Button>
            <Button type="warning" @click="onBack">返回</Button>
        </div>
        <div class="space"></div>

        <mySpin :loading="ajaxLoading"></mySpin>
        <!--退款-->
        <refund :refundId="refund.id" v-model="refund.show"></refund>
        <!--发货-->
        <sendOut :data="sendOut.data" v-model="sendOut.show" @sendsuccess="getDetail"></sendOut>
        <!--发送模版-->
        <sendTemplate :id="sendTemplate.id" v-model="sendTemplate.show"></sendTemplate>
        <!--查看物流-->
        <expressMessage :data="expressMessage.data" v-model="expressMessage.show"></expressMessage>
    </div>
</template>
<script>
import previewImage from '@/components/previewImage';
import { applyStateDef, getApplyState, getOrderStatus, getSfcModList, getSfcStatus, orderStatusDef, applyRefundStateDef } from '@/lib/order';
import refund from './comp/refund';
import sendOut from './comp/sendOut';
import sendTemplate from './comp/sendTemplate';
import expressMessage from './comp/expressMessage';
import { createId } from '@/lib/util';
import { reqOrderSfcList, reqOrderView } from '@/lib/request/auth2';

export default {
    name: 'goodOrderView',
    components: {
        previewImage,
        refund,
        sendOut,
        sendTemplate,
        expressMessage,
    },
    data() {
        this.orderStatusDef = orderStatusDef;
        this.applyStateDef = applyStateDef;
        this.applyRefundStateDef = applyRefundStateDef;
        return {
            isEdit: false,
            title: null,
            subTitle: null,
            stepCurrent: 0,
            detail: {},
            id: null,
            goodColumn: [
                { slot: 'goodMessage', title: '商品信息', width: 300 },
                { slot: 'price', title: '单价', minWidth: 100 },
                { key: 'num', title: '数量', minWidth: 60 },
                { slot: 'realPay', title: '小计', minWidth: 100 },
                { slot: 'afterSale', title: '售后服务', minWidth: 120 },
                { key: 'sfc_user', title: ' 操作人', minWidth: 120 },
            ],

            expressColumn: [
                { key: 'sendType', title: '配送方式', minWidth: 100 },
                { key: 'sfc_state', title: '物流状态', minWidth: 100 },
                { key: 'sfc_mod', title: '快递公司', minWidth: 100 },
                { key: 'sfc_order', title: '物流编号', minWidth: 150 },
                { key: 'sfc_time', title: '发货时间', minWidth: 120 },
                { key: 'sfc_user', title: '操作人', minWidth: 80 },
                { slot: 'manager', title: '操作', minWidth: 80 },
            ],
            sfcList: [],
            expressList: [],
            refund: {
                show: false,
                id: null,
            },
            sendOut: {
                show: false,
                data: {},
            },
            sendTemplate: {
                show: false,
                id: null,
            },
            expressMessage: {
                show: false,
                data: {},
            },
        };
    },
    mounted() {
        this.routeIdInject();

        getSfcModList(list => {
            this.sfcList = list;
            this.getDetail();
        });
    },
    watch: {
        //
    },
    methods: {
        getApplyState,
        getOrderStatus,

        //获取详情
        getDetail() {
            this.showAjaxLoading();
            reqOrderView({ id: this.id })
                .then(res => {
                    let detail = res?.data?.row || {};
                    this.detail = detail;
                    this.setTitle();

                    let sfc_mod_str = (this.sfcList.find(info => info.key == detail.sfc_mod) || {}).name || detail.sfc_mod || '-';
                    this.detail.sfc_mod_str = sfc_mod_str;
                    this.expressList = [
                        {
                            sendType: '快递物流',
                            sfc_mod: sfc_mod_str,
                            sfc_state: getSfcStatus(detail.sfc_state),
                            sfc_order: detail.sfc_order || '-',
                            sfc_time: detail.sfc_time || '-',
                            sfc_user: detail.sfc_user || '-',
                        },
                    ];
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        setTitle() {
            let { status, applyState, applyRefundState, applyRefundNote } = this.detail || {};
            let oTitle = {
                [orderStatusDef.payIng]: {
                    value: '商家已拍下，等待买家付款',
                    subValue: '如买家未在15分钟内付款，订单按自动关闭',
                    stepCurrent: 0,
                },
                [orderStatusDef.sendIng]: {
                    value: '商品已付款，等待卖家发货',
                    subValue: '买家已付款，请尽快发货',
                    stepCurrent: 1,
                },
                [orderStatusDef.sendEd]: {
                    value: '卖家已发货，等待买家确认收货',
                    subValue: '买家如在6天23小时59分钟22秒没有申请退款，交易将自动完成',
                    stepCurrent: 2,
                },
                [orderStatusDef.afterSale]: {
                    value: '买家发起售后申请',
                    subValue: applyState == this.applyStateDef.ed ? `${applyRefundState == this.applyRefundStateDef.agree ? '卖家同意退款，售后完成' : '卖家拒绝退款，拒绝理由：' + applyRefundNote}` : '买家发起售后申请，等待卖家处理',
                    stepCurrent: 2,
                },
                [orderStatusDef.orderEd]: {
                    value: '卖家已发货，买家已确认收货',
                    subValue: '买家已确认收货，交易已完成。',
                    stepCurrent: 3,
                },
                [orderStatusDef.orderClose]: {
                    value: '交易关闭',
                    subValue: '支付超时自动关闭',
                    stepCurrent: 1,
                },
            };
            let { value, subValue, stepCurrent } = oTitle[status] || {};
            this.title = value || '';
            this.subTitle = subValue || '';
            this.stepCurrent = stepCurrent;
        },
        //返回
        onBack() {
            this.$router.back();
        },
        //退款
        onShowRefund(rowInfo) {
            this.refund.id = rowInfo.id;
            this.refund.show = true;
        },
        //发货/修改物流
        onSendOut(isEdit = 0) {
            this.sendOut.data = {
                isEdit,
                id: createId(),
                detail: this.detail || {},
            };
            this.sendOut.show = true;
        },
        //查看物流
        onShowExpressMessage() {
            let { order_no, userAddress } = this.detail || {};
            this.showAjaxLoading();
            reqOrderSfcList({
                orderNo: order_no,
            })
                .then(res => {
                    let list = res?.data?.list || [];
                    list.forEach(item => {
                        //status 0-下单，1-运输，2-签收
                        item.title = item.status == 1 ? '运输中' : '';
                        item.note = item.cotent;
                    });
                    this.expressMessage.data = {
                        id: order_no,
                        expressList: list,
                        address: userAddress,
                    };
                    this.expressMessage.show = true;
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //发送模版
        onShowSendTemplate(rowInfo) {
            this.sendTemplate.show = true;
            this.sendTemplate.id = rowInfo.id;
        },
    },
};
</script>

<style lang="less" scoped>
.good_order_view {
    .card_item {
        .good_message {
            padding: 10px 0;
            & /deep/ div {
                font-size: 14px;
            }
        }

        .form_item {
            .form_label {
                align-self: flex-start;
            }
            .form_value {
                width: 250px;
                word-break: break-all;
            }
            &.cause {
                .form_value {
                    flex: 1;
                }
            }
        }
        &.log {
            & /deep/ .ivu-card-body {
                padding: 40px;
            }
            & /deep/ .ivu-timeline {
                & span,
                & div {
                    font-size: 14px;
                }
                margin-left: 150px;
            }
            .time {
                position: absolute;
                top: 0;
                right: calc(100% + 10px);
                white-space: nowrap;
                font-size: 14px;
            }
            .item {
                margin-top: 5px;
            }
        }
    }
    & .card_item ~ .card_item {
        margin-top: 20px;
    }

    .btns {
        gap: 30px;
    }
    .space {
        height: 50px;
    }
}
</style>
