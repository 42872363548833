import { render, staticRenderFns } from "./goodOrderView.vue?vue&type=template&id=133ae392&scoped=true&"
import script from "./goodOrderView.vue?vue&type=script&lang=js&"
export * from "./goodOrderView.vue?vue&type=script&lang=js&"
import style0 from "./goodOrderView.vue?vue&type=style&index=0&id=133ae392&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133ae392",
  null
  
)

export default component.exports